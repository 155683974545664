<template>
  <CustomDialog
    :title="title"
    @button1="cancel"
    @button2="getImage"
    v-model="dialog"
    :button2-text="mainButton"
    :main-btn-disabled="!file_uploaded"
  >
    <template v-slot:content>
      <v-card flat class="pt-2">
        <Loader :loading="loading" />
        <v-card-text v-if="file_uploaded">
          <v-img contain :src="image64"></v-img>
        </v-card-text>
        <v-sheet
          v-else
          color="whitesmoke"
          height="350"
          class="d-flex"
          style="border: 3px dotted #223a6b"
        >
          <v-btn
            @click="openUploader"
            color="primary"
            large
            class="text-none align-self-center mx-auto"
          >
            <v-icon left large>mdi-upload</v-icon> Upload Image
          </v-btn>
        </v-sheet>
        <v-file-input
          ref="fileuploader"
          class="d-none"
          v-model="file"
          @change="file_added"
          accept="image/*"
          label="File input"
        ></v-file-input>
      </v-card>
    </template>
    <template v-slot:extras>
      <v-btn
        depressed
        v-if="file_uploaded"
        @click="openUploader"
        color="primary"
        class="text-none align-self-center mx-auto"
      >
        <v-icon left large>mdi-upload</v-icon> Replace Image
      </v-btn>
    </template>
  </CustomDialog>
</template>
<script>
//Components
import Loader from '@/common/BaseComponents/Loader.vue'
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'
import request from '@/services/axios_instance'

export default {
  name: 'ImageUploader',
  components: {
    Loader,
    CustomDialog
  },
  props: {
    value: { type: Boolean, default: false },
    title: { type: String, default: 'Upload Image' },
    mainButton: { type: String, default: 'Save' },
    folder: { type: String, default: 'icons' },
    valueFormat: { type: String, default: 'attachment' } //or file
  },
  data: () => ({
    file_uploaded: false,
    image64: null,
    file: null,
    loading: false,
    dialog: false,
    submitting: false
  }),
  watch: {
    value: {
      handler(val) {
        this.dialog = val
        if (val) {
          this.file = null
          this.submitting = false
          this.loading = false
          this.file_uploaded = false
        }
      },
      immediate: true
    },
    dialog(val) {
      this.$emit('input', val)
    }
  },

  methods: {
    file_added(file) {
      const reader = new FileReader()
      reader.onload = () => {
        this.image64 = reader.result
        this.file_uploaded = true
      }
      reader.readAsDataURL(file)
    },
    getImage() {
      this.submitting = true
      if (this.valueFormat === 'file') this.emitFile()
      else this.emitAttachment()
    },
    emitFile() {
      this.$emit('file', this.file)
      Object.assign(this.$data, this.$options.data.apply(this))
    },
    emitAttachment() {
      var formData = new FormData()
      formData.append('file', this.file)
      request
        .post(`api2/attachments/dropzone`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(({ data }) => {
          this.$emit('file', data)
        })
        .finally(() => {
          Object.assign(this.$data, this.$options.data.apply(this))
        })
    },
    cancel() {
      this.dialog = false
    },
    openUploader() {
      this.$refs.fileuploader.$refs.input.click()
    }
  }
}
</script>
<style lang="scss" scoped>
.content {
  display: flex;
  justify-content: center;
}
</style>
