<template>
  <v-card min-height="100vh" outlined flat class="rounded-10 overflow-hidden">
    <v-card-title style="min-height: 100px" class="primary rounded-0">
      <v-row dense align="center" justify="space-between">
        <v-breadcrumbs light :items="paths">
          <template v-slot:divider>
            <v-icon color="white">mdi-chevron-right</v-icon>
          </template>
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item
              @click="$router.push({ path: item.href })"
              :disabled="item.disabled"
            >
              <span class="white--text cursor-pointer">{{ item.text }}</span>
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
        <v-btn
          @click="$router.go(-1)"
          small
          depressed
          class="text-none primary--text"
          color="white"
        >
          <v-icon color="primary" left>mdi-chevron-left</v-icon> Back
        </v-btn>
      </v-row>
    </v-card-title>
    <v-card-text class="pa-md-5 pa-sm-2">
      <v-card max-width="900" class="mx-auto" flat>
        <strong class="primary--text fs-25 fw-700 my-3">
          {{ isEdit ? 'Update Course' : 'Create Course' }}
        </strong>
        <v-form ref="form" v-model="valid">
          <v-row dense align="center" justify="start">
            <v-col cols="12" md="12" class="mb-2">
              <label class="subtitle-2 primary--text"> Title </label>
              <v-text-field
                filled
                outlined
                clearable
                dense
                placeholder="Enter title (e.g Laravel + VueJS SPA configuration)"
                :rules="[requiredRule()]"
                hide-details="auto"
                v-model="mapping.title"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense align="center" justify="space-between">
            <v-col cols="12" md="6" sm="12" class="mb-2">
              <label class="subtitle-2 primary--text"> Main Category </label>
              <v-select
                filled
                outlined
                placeholder="Select main category"
                dense
                :rules="[requiredRule()]"
                :items="categories"
                item-text="title"
                item-value="id"
                clearable
                hide-details="auto"
                v-model="mapping.category"
              >
              </v-select>
            </v-col>
            <v-col cols="12" md="6" sm="12" class="mb-2">
              <label class="subtitle-2 primary--text"> Sub Category </label>
              <v-select
                filled
                outlined
                placeholder="Select sub category"
                dense
                item-text="title"
                item-value="id"
                clearable
                :rules="[requiredRule()]"
                :items="subcategories"
                :disabled="!this.mapping.category || !subcategories.length"
                hide-details="auto"
                v-model="mapping.subcategory"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row dense align="center" justify="space-between">
            <v-col cols="12" md="6" sm="12" class="mb-2">
              <label class="subtitle-2 primary--text"> Language </label>
              <v-text-field
                filled
                outlined
                dense
                clearable
                placeholder="Comma separated languages (e.g English,Filipino)"
                :rules="[requiredRule()]"
                hide-details="auto"
                v-model="mapping.language"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="12" class="mb-2">
              <label class="subtitle-2 primary--text">
                Requirements (Optional)
              </label>
              <v-text-field
                filled
                outlined
                dense
                placeholder="e.g PHP and Javascript knowledge"
                hide-details="auto"
                v-model="mapping.requirements"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense align="center" justify="start" class="flex-column">
            <v-col cols="12" class="mb-2">
              <label class="subtitle-2 primary--text"> Preview Image </label>
              <v-card
                min-height="150"
                width="100%"
                outlined
                @click="uploadImage = true"
                class="d-flex align-center pa-1 justify-center border-1 border-color--primary border-dashed"
              >
                <v-img
                  v-if="mapping.attachment"
                  :src="mapping.attachment.url_inline"
                  contain
                  max-height="200"
                ></v-img>
                <v-icon v-else large color="primary">mdi-plus</v-icon>
              </v-card>
            </v-col>
            <v-col cols="12" class="mb-2">
              <label class="subtitle-2 primary--text">
                Description (Optional)
              </label>
              <Editor
                disabled-table
                disabled-video
                disabled-image
                v-model="mapping.contents"
                min-height="100"
              ></Editor>
            </v-col>
          </v-row>
          <draggable v-model="mapping.steps" tag="div">
            <v-card
              outlined
              class="mb-2 rounded-5"
              v-for="(step, index) in mapping.steps"
              :key="index"
            >
              <v-card-title
                class="primary cursor-drag d-flex align-center justify-space-between"
              >
                <label class="subtitle-2 white--text">
                  Section {{ index + 1 }}
                </label>
                <HelpToolTip
                  icon-color="white"
                  @click:icon="removeStep(index)"
                  text="Delete Step"
                  icon="mdi-delete"
                ></HelpToolTip>
              </v-card-title>
              <v-card-text class="py-2">
                <label class="subtitle-2 primary--text">
                  Section {{ index + 1 }} Title
                </label>
                <v-text-field
                  filled
                  outlined
                  dense
                  :rules="[requiredRule()]"
                  v-model="mapping.steps[index].title"
                  placeholder="Enter title"
                  hide-details="auto"
                ></v-text-field>
                <label class="subtitle-2 primary--text">
                  Section {{ index + 1 }} Content
                </label>
                <Editor
                  v-model="mapping.steps[index].contents"
                  min-height="100"
                  required
                ></Editor>
              </v-card-text>
            </v-card>
          </draggable>
          <v-row dense align="center" justify="space-between">
            <v-btn
              min-width="150"
              @click="addStep"
              color="primary"
              class="text-none my-2"
            >
              <v-icon left>mdi-plus</v-icon> Add Section
            </v-btn>
            <v-btn
              :loading="saving"
              :disabled="!valid || saving || !this.mapping.attachment"
              min-width="150"
              @click="saveUpdate"
              color="primary"
              class="text-none my-2"
            >
              <v-icon left>mdi-content-save</v-icon>
              {{ isEdit ? 'Save Changes' : 'Save' }}
            </v-btn>
          </v-row>
        </v-form>
      </v-card>
    </v-card-text>
    <ImageUploader
      main-button="Select"
      @file="mapping.attachment = $event"
      v-model="uploadImage"
    ></ImageUploader>
  </v-card>
</template>

<script>
import draggable from 'vuedraggable'
import Editor from '@/common/Editor/Editor.vue'
import request from '@/services/axios_instance'
import _ from 'lodash'
import ImageUploader from '@/common/Dialogs/ImageUploader.vue'
import { mapGetters } from 'vuex'
export default {
  components: { Editor, draggable, ImageUploader },
  data: () => ({
    valid: false,
    uploadImage: false,
    saving: false,
    categories: [],
    editItem: null,
    mapping: {
      attachment: null,
      title: null,
      category: null,
      subcategory: null,
      language: 'English',
      requirements: null,
      contents: null,
      steps: []
    },
    step_default: {
      title: null,
      contents: null
    }
  }),
  created() {
    if (!this.canManageCourse) {
      this.$router.push({ name: 'courses' })
      this.appSnackbar('Unauthorized access.', 'error')
    }
    this.fetchCategories()
    if (!this.isEdit) {
      this.addStep()
    }
  },
  watch: {
    'mapping.category': {
      handler: function (val) {
        this.mapping.subcategory = null
      },
      immediate: true
    },
    '$route.params.id': {
      handler: function (val) {
        if (val) {
          this.fetchCourse(val)
        }
      },
      immediate: true,
      deep: true
    },
    editItem: {
      handler: function (val) {
        if (val) {
          this.mapItem(val)
        }
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    ...mapGetters(['user']),
    canManageCourse() {
      return this.user.is_super_admin
    },
    isEdit() {
      return this.editItem ? true : false
    },
    payload() {
      return this.mapping
    },
    subcategories() {
      if (!this.mapping.category) {
        return []
      }
      let selected = this.categories.find((i) => i.id === this.mapping.category)
      return selected.subcategories
    },
    paths() {
      return [
        {
          text: 'Dashboard',
          disabled: true,
          href: '/dashboard'
        },
        {
          text: 'Training',
          disabled: false,
          href: '/dashboard/courses'
        },
        {
          text: this.isEdit ? 'Update' : 'Create',
          disabled: true,
          href: '/dashboard/courses'
        }
      ]
    }
  },
  methods: {
    addStep() {
      this.mapping.steps.push(_.cloneDeep(this.step_default))
    },
    fetchCategories() {
      request.get(`api/courses/categories`).then(({ data }) => {
        this.categories = data
      })
    },
    fetchCourse(id) {
      request.get(`api/courses/series/${id}`).then(({ data }) => {
        this.editItem = data
      })
    },
    removeStep(index) {
      this.mapping.steps.splice(index, 1)
    },
    saveUpdate() {
      this.saving = true
      if (this.isEdit) {
        request
          .put(`api/courses/series/${this.editItem.id}`, this.payload)
          .then(({ data }) => {
            this.appSnackbar('Series updated.')
            this.$router.push({
              name: 'courses-view',
              params: { slug: data.slug }
            })
          })
          .finally(() => {
            this.saving = false
          })
      } else {
        request
          .post(`api/courses/series`, this.payload)
          .then(({ data }) => {
            this.appSnackbar('Series created.')
            this.$router.push({
              name: 'courses-view',
              params: { slug: data.slug }
            })
          })
          .finally(() => {
            this.saving = false
          })
      }
    },
    mapItem(obj) {
      this.mapping = {
        attachment: obj.preview,
        title: obj.title,
        category: obj.maincategory.id,
        subcategory: obj.subcategory.id,
        language: obj.language,
        requirements: obj.requirements,
        contents: obj.contents,
        steps: obj.episodes
      }
    }
  }
}
</script>

<style></style>
